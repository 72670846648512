import React from "react";
import { Link } from "react-router-dom";
import Copyright from "./copyright";
import { IoIosMail } from "react-icons/io";
import { FaMapLocationDot, FaPhoneVolume } from "react-icons/fa6";
import { RiMobileDownloadLine } from "react-icons/ri";


const Footer_v1 = () => {

  return (
    <footer className="ltn__footer-area overflow-hidden">
      <div className="footer-top-area  section-bg-2 plr--5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-8 ">
              <div className="footer-widget footer-about-widget">
                <div className="footer-logo">
                  <div>
                    <img
                      className="site-logofooter "
                      src="https://dmq79vlehx2uk.cloudfront.net/trucklogo_compress_web.png"
                      alt="truck message Logo - All in one truck solutions"
                    />
                  </div>
                </div>
                {/* <p className='footercls'>Truck Message</p>	 */}
                <div className="footer-address">
                  <ul>
                    <li>
                      <div className=" para footer-address-icon">
                        <FaMapLocationDot />
                      </div>
                      <div className="footer-address-info footercls">
                        10/61,North Street, Near Sri Ambiga Press,
                        P.Velur,Namakkal-638182
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon para">
                        <FaPhoneVolume />
                      </div>
                      <div className="footer-address-info">
                        <a style={{ textDecoration: "none", color: "white" }} href="tel:8300745340">+91 83007 45340</a>
                        <br />
                        <a style={{ textDecoration: "none", color: "white" }} href="tel:8300678740">+91 83006 78740 </a>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon"><IoIosMail />


                      </div>
                      <div className="footer-address-info footercls ">
                        <a style={{ textDecoration: "none", color: "white" }} href="mailto:info@truckmessage.com">
                          info@truckmessage.com
                        </a>{" "}
                        <br />
                        <a style={{ textDecoration: "none", color: "white" }} href="mailto:sales@truckmessage.com">
                          sales@truckmessage.com
                        </a>
                        <br />
                        <a style={{ textDecoration: "none", color: "white" }} href="mailto:service@truckmessage.com">
                          service@truckmessage.com
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-6 col-lg-4 ">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">TruckMessage </h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/contact" className="footercls">
                        Contact us
                      </Link>
                    </li>
                    <li>
                    <Link to="/terms-conditions" className="footercls">
                    Terms & Conditions
                      </Link>
               </li>
                    <li>
                    <Link to="/privacy-policy" className="footercls">
                    Privacy policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-12 col-md-6 col-lg-4">

              <div className="footer-social mt-3">
                <div>
                  <p className="text-white fw-bold">Download Truck Message App</p>
                </div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mrdesigner.truckmessage&pcampaignid=web_share"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn cardbutton py-2 d-flex align-items-center gap-2 text-white"
                  style={{ width: "200px", borderRadius: "6px", fontSize: "16px" }}
                >
                  <svg
                    className="kOqhQd"
                    aria-hidden="true"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0,0h40v40H0V0z"></path>
                    <g>
                      <path
                        d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                        fill="#EA4335"
                      ></path>
                      <path
                        d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                        fill="#FBBC04"
                      ></path>
                      <path
                        d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                        fill="#4285F4"
                      ></path>
                      <path
                        d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                        fill="#34A853"
                      ></path>
                    </g>
                  </svg>
                  <span className="fw-bold">Download App</span>
                </a>
              </div>



              <div className="footer-widget footer-menu-widget mt-4 clearfix">
                <h4 className="footer-title">TruckMessage</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/contact" className="footercls">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions" className="footercls">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" className="footercls">Privacy policy</Link>
                    </li>
                  </ul>
                </div>

                {/* Social Media Icons */}
                <div className="footer-social mt-3">
                  <div>
                    <p className="text-primary">
                      Social Media links
                    </p>
                  </div>
                  <a href="https://www.facebook.com/truckmessageapp" target="_blank" rel="noopener noreferrer" className="me-3 ">
                    <i className="fab fa-facebook fa-lg"></i>
                  </a>
                  <a href="https://www.instagram.com/truckmessageapp/" target="_blank" rel="noopener noreferrer" className="me-3 ">
                    <i className="fab fa-instagram fa-lg"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/truckmessageapp/" target="_blank" rel="noopener noreferrer" className="me-3 ">
                    <i className="fab fa-linkedin fa-lg"></i>
                  </a>
                  <a href="https://www.pinterest.com/truckmessageapp/" target="_blank" rel="noopener noreferrer" className="me-3 ">
                    <i className="fab fa-pinterest fa-lg"></i>
                  </a>
                  <a href="https://x.com/truckmessageapp" target="_blank" rel="noopener noreferrer" className="me-3 ">
                    <i className="fab fa-twitter fa-lg"></i>
                  </a>
                  <a href="https://www.threads.net/@truckmessageapp" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-threads fa-lg"></i>
                  </a>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
}

export default Footer_v1;