import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GiMineTruck } from "react-icons/gi";
import { FaTruckLoading } from "react-icons/fa";
import { PiFireTruckFill } from "react-icons/pi";
import { MdPersonalInjury } from "react-icons/md";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { BsFuelPumpDiesel } from "react-icons/bs";
import { FaToriiGate } from "react-icons/fa6";
import { PiSpeedometerFill } from "react-icons/pi";
import { BsShieldShaded } from "react-icons/bs";
import { GiSpeedometer } from "react-icons/gi";
import { Card } from 'react-bootstrap';
import { BsFuelPump } from "react-icons/bs";



const Service_V1 = () => {
	const pageRender = useNavigate()

	return <div className="ltn__service-area section-bg-1 pt-5 pb-70 go-top">
		<div className="container">
			<div className="row align-items-stretch">
				<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Comprehensive Services</h6>
						<h1 className="section-title">Manage Your Loads, Trucks and Drivers in One Place. <br></br> Your trucking bussiness made Smarter</h1>
					</div>
				</div>
			</div>
			<div className="row gy-3">
				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/load-availability")}>
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><FaTruckLoading /></span>
							</div>
							<div className="ltn__feature-info">
								<h3 className='apara'>Find Loads </h3>
								<p>Keep your trucks busy with daily load updates!</p>
							</div>
						</Card.Body>
					</Card>
				</div>

				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/truck-availability")}>
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><PiFireTruckFill /></span>
							</div>
							<div className="ltn__feature-info">
								<h3 className='apara'>Book a Truck </h3>
								<p>Reliable trucks, anytime, anywhere.</p>
							</div>
						</Card.Body>
					</Card>
				</div>

				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/driver-availability")}>
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><MdPersonalInjury /></span>
							</div>
							<div className="ltn__feature-info">
								<h3 className='apara'>Driver Needs </h3>
								<p>Effortlessly Connecting You with Skilled Truck Drivers</p>
							</div>
						</Card.Body>
					</Card>
				</div>

				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/buy_sell")}>
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><GiMineTruck /></span>
							</div>
							<div className="ltn__feature-info">
								<h3 className='apara'>Truck Buy & Sell </h3>
								<p>The best deals for buying or selling trucks.</p>
							</div>
						</Card.Body>
					</Card>
				</div>



				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/toll-calculator")}>
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><FaToriiGate /></span>
							</div>
							<div className="ltn__feature-info">
								<h3 className='apara'>Toll Calculator</h3>
								<p>Plan your route, estimate your tolls.</p>
							</div>
						</Card.Body>
					</Card>
				</div>
				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/mileage_calculator")}>
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><PiSpeedometerFill /></span>
							</div>
							<div className="ltn__feature-info">
								<h3 className='apara'>Mileage Calculator</h3>
								<p>Optimize your fuel efficiency, mile by mile.</p>
							</div>
						</Card.Body>
					</Card>
				</div>
				{/* <div className="col-lg-4 col-sm-6 col-12">
						<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1">
	 py-5						<div className="ltn__feature-icon">
								<span><BsFuelPumpDiesel /></span>
							</div>
							<div className="ltn__feature-info">
								<h3><Link to="/fuelprice" className='apara'>Fuel Price</Link></h3>
								<p>Track and Optimize Your Fuel Costs Daily without missing</p>
							</div>
						</div>
					</div> */}


				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/expense-calculator")}>
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><FaHandHoldingDollar /></span>
							</div>
							<div className="ltn__feature-info">
								<h3 className='apara'>Expense Calculator</h3>
								<p>Track and manage your trucking expenses.</p>
							</div>
						</Card.Body>
					</Card>
				</div>

				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/petrol_bunk")} >
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><BsFuelPump /></span>
							</div>
							<div className="ltn__feature-info ">
								<h3 className='apara'>Prime Petrol Bunk</h3>
								<p>Premium amenities and advanced fueling for a hassle-free journey</p>
							</div>
						</Card.Body>
					</Card>
				</div>

				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/coming-soon")} >
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><GiSpeedometer /></span>
							</div>
							<div className="ltn__feature-info ">
								<h3 className='apara'>Fast Tag Recharge  </h3>
								<p>Quick and hassle-free toll payments</p>
							</div>
						</Card.Body>
					</Card>
				</div>

				<div className="col-lg-4 col-sm-6 col-12" onClick={() => pageRender("/coming-soon")}>
					<Card className='border-0 w-100 h-100'>
						<Card.Body className="ltn__feature-item-6 text-center bg-white  box-shadow-1 py-5">
							<div className="ltn__feature-icon">
								<span><BsShieldShaded /></span>
							</div>
							<div className="ltn__feature-info">
								<h3 className='apara'>Insurance renewal</h3>
								<p>Tap, renew, and drive on – insurance made easy!</p>
							</div>
						</Card.Body>
					</Card>
				</div>
			</div>
		</div>
	</div>
}

export default Service_V1

