import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Cookies from 'js-cookie';

const AddPetrolBunk = ({ setRefresh }) => {
    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const [formData, setFormData] = useState({
        petrolBunkName: "",
        ownerName: "",
        amenities: [],
        discounts: "",
    });

    const amenitiesList = [
        "Diesel Discounts",
        "CNG Availability",
        "Spacious Truck Parking",
        "Clean Toilets",
        "Rest Areas",
        "Credit/Debit Card Payments Accepted",
        "Diesel Fuel Card Payments Accepted",
        "Automated Bills with Pump Readings",
        "Free Commercial Air for Tyres",
        "On-Site ATM",
        "Vehicle Oil for Sale",
        "Drinking Water",
        "Pollution Control Check (PUC) (Within 500m)",
        "Water Wash Services (Within 500m)",
        "Puncture Repair (Within 500m)",
        "Minor Maintenance Services (Within 500m)",
        "Wheel Alignment (Within 500m)",
        "Weighbridge (Within 500m)",
        "General Stores (Within 500m)",
        "Hotels/Dhabas(Within 500m)"
    ];


    const initializeMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 20.5937, lng: 78.9629 },
            zoom: 5,
        });
        const marker = new window.google.maps.Marker({ map, draggable: true });
        markerRef.current = marker;

        const autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById("locationPicker"),
            { componentRestrictions: { country: "in" }, fields: ["geometry", "formatted_address"] }
        );

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const { location } = place.geometry;
                map.setCenter(location);
                marker.setPosition(location);
                setAddress(place.formatted_address);
                setCoordinates({ lat: location.lat(), lng: location.lng() });
            }
        });

        const updateLocation = (latLng) => {
            marker.setPosition(latLng);
            setCoordinates({ lat: latLng.lat(), lng: latLng.lng() });
            new window.google.maps.Geocoder().geocode({ location: latLng }, (results, status) => {
                if (status === "OK" && results[0]) setAddress(results[0].formatted_address);
            });
        };

        map.addListener("click", (e) => updateLocation(e.latLng));
        marker.addListener("dragend", (e) => updateLocation(e.latLng));
    };

    useEffect(() => {
        if (!window.google) {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
            script.onload = initializeMap;
            document.body.appendChild(script);
        } else initializeMap();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox"
                ? checked
                    ? [...prev.amenities, value]
                    : prev.amenities.filter((a) => a !== value)
                : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let userId = window.atob(Cookies.get("usrin"));
        // Validation
        if (!formData.petrolBunkName || !formData.ownerName || !address || !coordinates.lat || !coordinates.lng) {
            alert("Please fill out all required fields!");
            return;
        }

        // Parse and clean data
        const formattedDiscounts = formData.discounts
            .split(",")
            .map((d) => d.trim()) // Remove extra spaces
            .filter((d) => d); // Exclude empty strings

        const formattedAmenities = formData.amenities.map((amenity) => amenity.trim());

        // Payload
        const payload = {
            petrol_bunk_name: formData.petrolBunkName?.trim(),
            owner_name: formData.ownerName?.trim(),
            location: address,
            latitude: coordinates.lat.toString(),
            longitude: coordinates.lng.toString(),
            discount: formattedDiscounts,
            amenities: formattedAmenities,
            user_id: userId
        };

        try {
            const { data } = await axios.post(
                "https://truck.truckmessage.com/petrol_bunk_entry",
                payload
            );

            if (data?.error_code === 0) {
                setRefresh(true)

                toast.success("Petrol Bunk added successfully!");

                document.getElementById("closeAddPetrolBunkModel")?.click()
            } else {
                toast.error(data?.message)
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to add Petrol Bunk. Please try again!");
        }
    };

    return (
        <div className="container">
            <div className="row">

                <div className="col-lg-14 mt-4">
                    <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                    >
                        Add Your Petrol Bunk
                    </button>
                </div>
            </div>

            <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Add Petrol Bunk
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" id="closeAddPetrolBunkModel"></button>
                        </div>
                        <div className="modal-body">
                            <form id="petrolBunkForm" onSubmit={handleSubmit}>
                                {[
                                    { label: "Petrol Bunk Name", name: "petrolBunkName" },
                                    { label: "Owner Name", name: "ownerName" },
                                    { label: "Discount per liter (₹ Rupees)", name: "discounts" },
                                ].map(({ label, name }) => (
                                    <div className="form-group" key={name}>
                                        <label htmlFor={name}>{label}:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id={name}
                                            name={name}
                                            onChange={handleChange}
                                            placeholder={`Enter ${label.toLowerCase()}`}
                                            required
                                        />
                                    </div>
                                ))}
                                <div className="form-group">
                                    <label>Amenities:</label>
                                    {amenitiesList.map((amenity) => (
                                        <div className="form-check" key={amenity}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={amenity}
                                                name="amenities"
                                                value={amenity}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor={amenity}>
                                                {amenity}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="locationPicker">Location:</label>
                                    <input
                                        type="text"
                                        id="locationPicker"
                                        className="form-control"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        placeholder="Enter or pick a location in India"
                                    />
                                    <div
                                        ref={mapRef}
                                        style={{
                                            width: "100%",
                                            height: "200px",
                                            marginTop: "10px",
                                            border: "1px solid #ccc",
                                        }}
                                    ></div>
                                </div>

                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary">Add Petrol Bunk</button>
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPetrolBunk;
